<template>
  <v-card flat>
    <h3 v-if="form.protocolCompleted" class="text-danger text-right">
      Добавена към протокол: {{ form.protocolName }}
    </h3>
    <b-row>
      <b-form-group
        class="col-md-4"
        id="item-bill-number-group"
        :label="$t('MENU.ITEM.ACTIVITIES.BILL_OF_LADING_NUMBER')"
        label-for="item-bill-number-input"
      >
        <div class="input-group">
          <FormInput
            id="bill-number-input"
            type="text"
            name="bill_number"
            :i18n="{
              label: 'MENU.ITEM.ACTIVITIES.BILL_OF_LADING_NUMBER',
              placeholder: $t('MENU.ITEM.ACTIVITIES.BILL_OF_LADING_NUMBER'),
            }"
            :validateState="validateState('billNumber')"
            v-model="form.billNumber"
            @blur="handleBillNumberBlur"
          ></FormInput>
        </div>
      </b-form-group>

      <b-form-group
        class="col-md-4"
        id="item-assignors-group"
        v-bind:label="$t('MENU.ITEM.ASL.ASSIGNOR')"
        label-for="item-assignor-input"
      >
        <FormSelect
          id="item-assignors-input"
          type="select"
          clearable
          name="itemAssignors"
          :options="assignors"
          v-model="form.assignorId"
          @input="fetchAssignorLocations"
        ></FormSelect>
      </b-form-group>

      <b-form-group
        class="col-md-4"
        id="item-locations-group"
        v-bind:label="$t('MENU.ITEM.ASL.LOCATION')"
        label-for="item-locations-input"
      >
        <FormSelect
          id="item-locations-input"
          type="select"
          clearable
          name="itemLocations"
          :options="locations"
          v-model="form.locationId"
          @input="fetchServices"
        ></FormSelect>
      </b-form-group>

      <b-form-group
        class="col-md-4"
        id="item-vehicle-group"
        :label="$t('FORM_PLACEHOLDERS.MACHINE')"
        label-for="item-reg-plate-input"
      >
        <FormSelect
          id="item-vehicle-number-input"
          type="select"
          clearable
          name="itemMachine"
          :options="vehicles"
          v-model="form.machine"
          :validateState="validateState('machine')"
          @input="getVehicle($event)"
        ></FormSelect>
      </b-form-group>

      <b-form-group
        class="col-md-4"
        id="item-measures-group"
        :label="$t('MENU.ITEM.STAFF.STAFF')"
        label-for="item-measures-input"
      >
        <FormSelect
          :key="staffListKey"
          id="item-staff-input"
          type="select"
          clearable
          name="itemStaff"
          :options="staffList"
          v-model="form.staffId"
        ></FormSelect>
      </b-form-group>

      <b-form-group
        class="col-md-4"
        id="item-bill-number-group"
        :label="$t('MENU.ITEM.ACTIVITIES.BILL_OF_LADING_DATE')"
        label-for="item-bill-number-input"
      >
        <FormDatepicker
          id="bill-of-lading-date-input"
          type="date"
          name="bol_date"
          :i18n="{
            placeholder: 'FORM_LABELS.DATE',
          }"
          :validateState="validateState('bolDate')"
          v-model="form.bolDate"
          :max="today"
          @input="setServiceDates()"
        ></FormDatepicker>
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group
        class="col-md-12"
        id="item-bill-number-group"
        :label="$t('MENU.ITEM.ACTIVITIES.BILL_OF_LADING_NOTE')"
        label-for="item-bill-number-input"
      >
        <div class="input-group">
          <FormInput
            id="bill-note-input"
            type="text"
            name="bill_number"
            :i18n="{
              label: 'FORM_LABELS.NOTE',
              placeholder: $t('FORM_LABELS.NOTE'),
            }"
            :validateState="{}"
            v-model="form.note"
          ></FormInput>
        </div>
      </b-form-group>
    </b-row>

    <b-row>
      <div class="col-md-4 d-flex align-center">
        <h3>Дейности ({{ form.work.length }})</h3>
      </div>
      <div class="col-md-8 text-right">
        <button
          v-if="form.locationId"
          class="btn btn-info mr-2"
          @click="openModal"
        >
          Услуги {{ selectedLocationName }}
        </button>
        <button class="btn btn-success" v-on:click="handleAddWork">
          <strong>+ Добави дейност</strong>
        </button>
      </div>
    </b-row>

    <b-row class="service-section" v-for="(item, i) in form.work" :key="i">
      <b-col :md="4">
        <b-form-group
          :label="$t('MENU.ITEM.ACTIVITIES.BILL_OF_LADING_WORKING_DATE')"
          label-for="item-bill-number-input"
        >
          <FormDatepicker
            type="date"
            :i18n="{
              placeholder: 'FORM_LABELS.DATE',
            }"
            v-model="form.work[i].dateWorked"
            :max="today"
          ></FormDatepicker>
        </b-form-group>
      </b-col>
      <b-col :md="4">
        <b-form-group
          id="item-measures-group"
          :label="$t('FORM_LABELS.SERVICE')"
          label-for="item-measures-input"
        >
          <FormSelect
            clearable
            type="select"
            :options="services"
            v-model="form.work[i].serviceId"
            @input="handleServiceChange(i, $event)"
          ></FormSelect>
        </b-form-group>
      </b-col>
      <b-col :md="2">
        <b-form-group
          :label="$t('FORM_LABELS.QTY')"
          label-for="item-bill-qty-input"
        >
          <div class="input-group">
            <FormInput
              type="number"
              :i18n="{
                label: 'FORM_LABELS.QTY',
                placeholder: $t('FORM_LABELS.QTY'),
              }"
              v-model="form.work[i].serviceQty"
              :validateState="{}"
              @input="totalQty"
            ></FormInput>
          </div>
        </b-form-group>
      </b-col>
      <b-col :md="2">
        <b-form-group
          id="item-measures-group"
          v-bind:label="$t('MENU.ITEM.MEASURES.MEASURE')"
          label-for="item-measures-input"
        >
          <FormSelect
            type="select"
            disabled
            clearable
            :options="measures"
            v-model="form.work[i].measureId"
          ></FormSelect>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="item-note-group"
          v-bind:label="$t('FORM_LABELS.NOTE')"
          label-for="item-note-input"
        >
          <FormInput
            type="text"
            :i18n="{
              label: 'FORM_LABELS.NOTE',
              placeholder: $t('FORM_LABELS.NOTE'),
            }"
            v-model="form.work[i].note"
            :validateState="{}"
          ></FormInput>
        </b-form-group>
      </b-col>
      <b-col v-if="form.work[i].isUnloading" :md="5">
        <b-form-group
          id="item-unloading-group"
          v-bind:label="$t('FORM_LABELS.UNLOADING')"
          label-for="item-unloading-input"
        >
          <FormSelect
            type="select"
            clearable
            :options="subcontractorLocations"
            v-model="form.work[i].subcontractorsLocationId"
          ></FormSelect>
        </b-form-group>
      </b-col>
      <b-col v-if="i > 0" :md="1">
        <b-form-group class="text-right">
          <button
            class="btn btn-danger mt-6 pr-2 pl-3"
            v-on:click="form.work.splice(i, 1)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </b-form-group>
      </b-col>
    </b-row>
    <ServicesModal
      ref="ServicesModal"
      :location-id="form.locationId"
      @updated="handleServicesUpdated"
    ></ServicesModal>
  </v-card>
</template>

<script>
import { FETCH_MACHINES } from "@/modules/machines/store/machine.module";
import {
  FETCH_RENTED_MACHINES,
  FETCH_RENTED_MACHINE,
} from "@/modules/rented-machines/store/rentedmachine.module";
import { FETCH_LOCATION_SERVICES } from "@/modules/assignorslocation/store/location-service.module";
import { FETCH_ALL_STAFF } from "@/modules/staff/store/staff.module";
import { FETCH_ASSIGNOR_LOCATIONS } from "@/modules/assignorslocation/store/location.module";
import { FETCH_MEASURES } from "@/modules/measures/store/measures.module";
import { FETCH_ASSIGNORS } from "@/modules/assignors/store/assignors.module";
import { FETCH_SERVICES } from "@/modules/services/store/service.module";
import { FETCH_BILL_OF_LADINGS } from "../store/bill-of-lading.module";
import { FETCH_SUBCONTRACTOR_LOCATIONS } from "@/modules/subcontractorslocation/store/location.module";

import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";

import { validationMixin } from "vuelidate";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import FormSelect from "../../../view/content/forms/components/FormSelect";
import ServicesModal from "./ServicesModal";

import Multiselect from "vue-multiselect";

export default {
  name: "BillOfLadingForm",
  mixins: [validationMixin, formBuilderMixin],
  components: {
    FormSelect,
    FormInput,
    Multiselect,
    FormDatepicker,
    ServicesModal,
  },
  props: {
    form: Array,
  },
  data() {
    return {
      today: new Date().toISOString().slice(0, 10),
      errors: {},
      locations: [],
      measures: [],
      machines: [],
      assignors: [],
      vehicles: [],
      staffList: [],
      services: [],
      subcontractorLocations: [],
      staffListKey: 0,
    };
  },
  computed: {
    selectedLocationName() {
      const loc = this.locations.find((s) => s.id === this.form.locationId);
      return loc ? loc.name : null;
    },
  },
  validations() {
    const tmpValidationObject = {
      form: {
        billNumber: this.setItemValidations({
          required: true,
        }),
        bolDate: this.setItemValidations({
          required: true,
        }),
        machine: this.setItemValidations({
          required: true,
        }),
      },
    };

    return tmpValidationObject;
  },
  mounted() {
    this.fetchServices();

    this.$store
      .dispatch(
        FETCH_ASSIGNORS,
        this.$url.transformParams({
          onlySelectValues: true,
          selectValueKey: "company_name",
        })
      )
      .then((data) => {
        this.assignors = data.data.items;
        if (this.form.assignorId) {
          this.fetchAssignorLocations(this.form.assignorId);
        }
      });

    this.$store.dispatch(FETCH_MEASURES).then((data) => {
      this.measures = data.data.items;
    });

    this.$store.dispatch(FETCH_MACHINES, "?active=1").then((data) => {
      let items = data.data.items;
      let vehicles = [];

      if (items && items.length > 0) {
        for (let i = 0; i < items.length; i++) {
          vehicles.push({
            id: {
              machinableId: items[i].id,
              machinableType: items[i].expenseType,
              lastUsedStaffId: items[i].lastUsedStaffId,
            },
            name: items[i].name + " - " + "Собствена",
          });
        }
      }
      this.vehicles = vehicles;

      this.$store.dispatch(FETCH_RENTED_MACHINES).then((data) => {
        let items = data.data.items;
        if (items && items.length > 0) {
          for (let i = 0; i < items.length; i++) {
            this.vehicles.push({
              id: {
                machinableId: items[i].id,
                machinableType: items[i].expenseType,
                lastUsedStaffId: "",
              },
              name: items[i].name + " - " + items[i].subcontractorName,
            });
          }
        }
        if (this.form.machine) {
          this.fetchStaff(this.form.machine);
        }
      });
    });

    this.$store
      .dispatch(
        FETCH_SUBCONTRACTOR_LOCATIONS,
        this.$url.transformParams({
          onlySelectValues: true,
          selectValueKey: "location_name",
          onlyMaterial: true,
        })
      )
      .then((data) => {
        this.subcontractorLocations = data.data.items;
      });
  },
  methods: {
    fetchServices(id = null) {
      if (!id) {
        this.$store.dispatch(FETCH_SERVICES).then((data) => {
          this.services = data.data.items.map((s) => ({
            id: s.serviceId,
            name: s.name,
            measureId: s.measureId,
            measureName: s.measureName,
            isUnloading: s.isUnloading,
          }));
        });
      } else {
        this.$store.dispatch(FETCH_LOCATION_SERVICES, id).then((data) => {
          this.services = data.data.map((s) => ({
            id: s.serviceId,
            name: s.name,
            measureId: s.measureId,
            measureName: s.measureName,
            isUnloading: s.isUnloading,
          }));

          this.checkExistingServices();
        });
      }
    },

    fetchAssignorLocations(id) {
      this.locations = [];
      this.$store
        .dispatch(
          FETCH_ASSIGNOR_LOCATIONS,
          this.$url.transformParams({
            assignorId: id,
          })
        )
        .then((data) => {
          this.locations = data.data.items || [];
          this.form.locationId = this.locations.find(
            (l) => l.id === this.form.locationId
          )
            ? this.form.locationId
            : null;

          if (this.locations.length === 1) {
            this.form.locationId = this.locations[0].id;
          }

          this.fetchServices(this.form.locationId);
        });
    },

    openModal() {
      this.$refs.ServicesModal.show();
    },

    getVehicle(event) {
      this.fetchStaff(event);
    },
    setServiceDates() {
      this.form.work.forEach((fs) => {
        fs.dateWorked = this.form.bolDate;
      });
    },
    handleAddWork() {
      const lastWork = this.form.work[this.form.work.length - 1];
      this.form.work.push({
        ...lastWork,
      });
      this.form.work[this.form.work.length - 1].id = null;
    },
    fetchStaff(event) {
      this.staffList = [];

      if (event && event.machinableType == "OwnMachineExpense") {
        this.$store.dispatch(FETCH_ALL_STAFF, "?active=1").then((data) => {
          const items = data.data.items || [];
          this.staffList = items.map((s) => ({
            id: s.id,
            name: s.name,
          }));
          if (event.lastUsedStaffId != "") {
            this.form.staffId = event.lastUsedStaffId;
          } else {
            this.form.staffId = null;
          }
        });
      } else if (event && event.machinableType == "RentedMachineExpense") {
        this.$store
          .dispatch(FETCH_RENTED_MACHINE, event.machinableId)
          .then((data) => {
            const rented_machine = data.data;
            this.staffList = [{ id: "", name: rented_machine.staffName }];
            this.staffListKey = this.staffListKey + 1;
            this.form.staffId = "";
          });
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    resetVForm() {
      this.$v.$reset();
    },
    handleAddKariera(i) {
      this.form.work[i].showKariera = true;
    },
    handleServicesUpdated() {
      this.fetchServices(this.form.locationId);
    },
    checkExistingServices() {
      this.form.work.forEach((w, i) => {
        if (this.services && this.services.length === 1) {
          w.serviceId = this.services[0].id;
          this.handleServiceChange(i, w.serviceId);
        }
        const serviceExist = this.services.find((s) => s.id === w.serviceId);
        if (!serviceExist) {
          w.serviceId = null;
          this.handleServiceChange(i, w.serviceId);
        }
      });
    },
    handleServiceChange(i, id) {
      let work = this.form.work[i];
      const service = this.services.find((s) => s.id === id);
      work.measureId = service ? service.measureId : null;
      work.isUnloading = service ? service.isUnloading : 0;
      work.subcontractorsLocationId = work.isUnloading
        ? work.subcontractorsLocationId
        : null;
    },
    handleBillNumberBlur() {
      this.$store
        .dispatch(
          FETCH_BILL_OF_LADINGS,
          this.$url.transformParams({
            billNumber: this.form.billNumber,
            itemsPerPage: 1,
          })
        )
        .then((data) => {
          if (data.data.items && data.data.items.length > 0) {
            if (data.data.items[0].id != this.form.id) {
              this.$notify({
                group: "notify",
                type: "warning",
                title: `<i class='flaticon-close'></i> ${this.$t(
                  "ALERTS.WARNING"
                )}`,
                text: this.$t("ALERTS.BILL_NUMBER_EXIST"),
              });
            }
          }
        });
    },
  },
};
</script>

<style type="text/css">
.service-section {
  border: 1px solid #ecf0f3;
  margin-bottom: 5px !important;
  border-radius: 0.42rem;
  -webkit-box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
}
</style>
