import { GPS_CHECK } from "@/modules/machines/store/machine.module";
import moment from "moment";
export default {
  methods: {
    checkGpsData(machine, work) {
      return new Promise((resolve) => {
        let errorMessage = "";
        if (machine.machinableType === "OwnMachineExpense") {
          this.$store
            .dispatch(GPS_CHECK, {
              id: machine.machinableId,
              work: work,
            })
            .then((data) => {
              if (data.error === true) {
                errorMessage +=
                  "<h2 class='text-center'>Разминаване в данните от GPS и въведените данни</h2>";
                data.data.forEach((w) => {
                  if (w.warning) {
                    const date = moment(w.date).format("DD.MM.YYYY г.");
                    let errorSubMessage = "";
                    if (w.duration == "00:00") {
                      errorSubMessage = "Няма данни за работа";
                    } else {
                      errorSubMessage = `Разминаване: ~ ${w.diff} часа<br/>`;
                      errorSubMessage += `Първа/посл. раб.: ${w.range}<br/>`;
                      errorSubMessage += `Време запален: ${w.workDuration} часа`;
                    }
                    errorMessage += `<br /> ${date} - ${errorSubMessage}`;
                    errorMessage += `<hr />`;
                  }
                });
                errorMessage +=
                  "<b>Сигурни ли сте, че искате да запазите товарителницата?</b>";
                errorMessage +=
                  "<br /> <sub>За повече информация натиснете <a href='https://my.itrack.bg/reports/worktime' target='_blank'>тук</a></sub>";
                this.$dialog
                  .confirm(errorMessage, {
                    html: true,
                    customClass: "confirmation-modal",
                  })
                  .then(() => {
                    resolve();
                  });
              } else {
                resolve();
              }
            });
        } else {
          resolve();
        }
      });
    },
  },
};
