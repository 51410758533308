<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-bill-of-lading' }">{{
            $t("MENU.ITEM.ACTIVITIES.BILL_OF_LADING")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            class="mr-2"
            :defaultAction="'continue'"
            :isLoading="isLoadingTankCharge"
            with-copy
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
          <button
            class="btn btn-danger font-weight-bolder"
            @click="handleDelete"
          >
            {{ $t("BUTTON.DELETE") }}
          </button>
        </div>
      </template>
      <template v-slot:body>
        <BillOfLadingForm
          v-if="!loading"
          ref="BillOfLadingForm"
          :form="form"
        ></BillOfLadingForm>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            class="mr-2"
            :defaultAction="'continue'"
            :isLoading="isLoadingBillOfLading"
            with-copy
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
          <button
            class="btn btn-danger font-weight-bolder mr-2"
            @click="handleDelete"
          >
            {{ $t("BUTTON.DELETE") }}
          </button>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import {
  FETCH_BILL_OF_LADING,
  UPDATE_BILL_OF_LADING,
  DELETE_BILL_OF_LADING,
} from "@/modules/bill-of-lading/store/bill-of-lading.module";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";
import KTCard from "@/view/content/Card.vue";
import BillOfLadingForm from "./BillOfLadingForm.vue";

import billOfLadingMixin from "./mixins/billOfLading.mixin";

import { mapGetters, mapState } from "vuex";

export default {
  name: "BillOfLadingAdd",
  mixins: [billOfLadingMixin],
  components: {
    KTCard,
    SaveButtonDropdown,
    BillOfLadingForm,
  },
  data() {
    return {
      loading: true,
      errors: {},
      form: {
        staffId: null,
        work: [
          {
            showKariera: false,
            serviceId: null,
            measureId: null,
            unloadingMeasure: null,
            qty: 1,
            dateWorked: null,
            serviceQty: null,
          },
        ],
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.ACTIVITIES.BILL_OF_LADING"),
        route: { name: "list-bill-of-lading" },
      },
      { title: this.$i18n.t("LABELS.ADD_NEW") },
    ]);
    this.$store
      .dispatch(FETCH_BILL_OF_LADING, this.$route.params.id)
      .then((data) => {
        let res = data.data;

        this.form = res;
        this.form_services = res.work;
        this.loading = false;
      });
  },

  computed: {
    ...mapGetters(["isLoadingBillOfLading"]),
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  watch: {},
  methods: {
    onSave(next) {
      let vm = this;
      this.$refs.BillOfLadingForm.$v.form.$touch();
      if (this.$refs.BillOfLadingForm.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly",
        });
        return;
      }
      let form = this.form;

      this.checkGpsData(this.form.machine, this.form.work).then(() => {
        this.$store
          .dispatch(UPDATE_BILL_OF_LADING, {
            id: this.$route.params.id,
            payload: form,
          })
          .then((data) => {
            vm.errors = data.data.errors;
            vm.$notify({
              group: "notify",
              type: "success",
              title: "<i class='flaticon2-checkmark'></i> Success",
              text: data.message,
            });
            // let itemEdit = data.data;
            if (next == "continue") {
            }
            if (next == "copy") {
              //New
              this.form.billNumber = null;
              this.$router.push({
                name: "add-bill-of-lading",
                params: { formData: this.form },
              });
            }
            if (next == "new") {
              //New
              this.$router.push({ name: "add-bill-of-lading" });
            }
            if (next == "exit") {
              this.$router.push({ name: "list-bill-of-lading" });
            }
          })
          .catch((response) => {
            this.errors = this.$errors.format(response.data.errors);
            this.$notify({
              group: "notify",
              type: "error",
              title: this.$t("ALERTS.ERROR"),
              text: response.data.message,
            });
          });
      });
    },
    handleDelete() {
      this.$dialog.confirm(this.$t("BUTTON.PLEASE_CONFIRM")).then(() => {
        this.$store
          .dispatch(DELETE_BILL_OF_LADING, this.$route.params.id)
          .then((data) => {
            this.$notify({
              group: "notify",
              type: "success",
              title: "<i class='flaticon2-checkmark'></i> Success",
              text: data.message,
            });
            this.$router.push({ name: "list-bill-of-lading" });
          })
          .catch((response) => {
            console.log(response);
            this.$notify({
              group: "notify",
              type: "error",
              title: this.$t("ALERTS.ERROR"),
              text: response.data.message,
            });
          });
      });
    },
  },
};
</script>
